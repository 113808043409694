import img1 from "../../assets/images/catalog/Porsche Caayenne.jpg"
import img2 from "../../assets/images/catalog/BMW E60.jpg"
import img3 from "../../assets/images/catalog/Mercedes W124 E-class.jpg"
import img4 from "../../assets/images/catalog/Chevrolet Lanos.jpg"
import img5 from "../../assets/images/catalog/VW Passat B4.jpg"
import img6 from "../../assets/images/catalog/Infinity FX37.jpg"

export const metalCars = [
  {
    link: "/metallicheskie/katalizator-porsche-cayenne",
    name: "Porsche Cayenne 2014",
    year: "2014",
    engine: "4.8",
    type: "бензин",
    img: img1,
    price: "40 000",
    date: "14.07.2021",
  },
  {
    link: "/metallicheskie/katalizator-bmw-e60",
    name: "BMW E60",
    year: "2004",
    engine: "2.5",
    type: "бензин",
    img: img2,
    price: "23 000",
    date: "28.06.2021",
  },
  {
    link: "/metallicheskie/katalizator-mercedes-w124",
    name: "Mercedes W124",
    year: "1993",
    engine: "2.3",
    type: "бензин",
    img: img3,
    price: "8 500",
    date: "13.05.2021",
  },
  {
    link: "/metallicheskie/katalizator-chevrolet-lanos",
    name: "Chevrolet Lanos",
    year: "2010",
    engine: "1.5",
    type: "бензин",
    img: img4,
    price: "7 700",
    date: "27.02.2021",
  },
  {
    link: "/metallicheskie/katalizator-volkswagen-passat-b4",
    name: "VW Passat B4",
    year: "1995",
    engine: "2.0",
    type: "бензин",
    img: img5,
    price: "12 100",
    date: "16.02.2021",
  },
  {
    link: "/metallicheskie/katalizator-infinity-fx37",
    name: "Infinity FX37",
    year: "2008",
    engine: "3.7",
    type: "бензин",
    img: img6,
    price: "11 000",
    date: "05.02.2021",
  },
]

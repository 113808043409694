import React from "react"
import { Link } from "gatsby"

import { ChevronRightIcon } from "@heroicons/react/solid"

import ContactSection from "../Home/ContactSection"

const PricePage = ({ data }) => {
  return (
    <>
      <section
        id="ceramic"
        aria-labelledby="ceramic-data-heading"
        className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
      >
        <div className="mt-6 grid grid-cols-1 space-y-2 sm:grid-cols-3 sm:gap-x-6 sm:space-y-0">
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol className="flex min-w-max items-center space-x-4">
              <li>
                <div className="flex">
                  <Link
                    to="/"
                    className="text-base font-medium text-gray-500 hover:text-gray-700"
                  >
                    Главная
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    to="/metallicheskie"
                    className="ml-4 text-base font-medium text-gray-500 hover:text-gray-700"
                  >
                    Металлические катализаторы
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    to={data.link}
                    className="ml-4 text-base font-medium text-gray-500 hover:text-gray-700"
                  >
                    {data.name}
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        <div
          id="ceramic-data"
          className="grid grid-cols-1 gap-x-6 gap-y-6 pt-4 lg:grid-cols-2"
        >
          <img src={data.img} alt={`Катализатор от ${data.name}`} />
          <div className="max-w-lg px-1">
            <h1
              id="ceramic-data-heading"
              className="text-left text-4xl font-extrabold md:text-5xl"
            >
              {data.name}
            </h1>

            <h2 className="pt-2 text-left text-xl font-light md:text-2xl">
              Катализатор {data.name} (выкуп у частного лица)
            </h2>

            <div className="space-between grid grid-cols-2 gap-4 pt-6 text-xl">
              <div>Год выпуска: </div>
              <div className="text-right">
                <span className="bg-corp-orange p-1 text-3xl font-semibold text-white">
                  {data.year}
                </span>
              </div>

              <div>Объем двигателя: </div>
              <div className="text-right">
                <span className="bg-corp-orange p-1 text-3xl font-semibold text-white">
                  {data.engine}
                </span>
              </div>

              <div>Тип двигателя: </div>
              <div className="text-right">
                <span className="bg-corp-orange p-1 text-3xl font-semibold text-white">
                  {data.type}
                </span>
              </div>

              <div>Выплата составила: </div>
              <div className="text-right">
                <span className="bg-corp-orange p-1 text-3xl font-semibold text-white">
                  {data.price} ₽*
                </span>
              </div>
            </div>

            <p className="pt-12 text-lg text-gray-600">
              Дата публикации: {data.date}
            </p>
            <p className="pt-2 text-justify text-lg text-gray-600">
              *Цена действительна на дату публикации поста и учитывает текущие
              котировки биржи металлов. Точная стоимость определяется с помощью
              анализатора.
            </p>
            <p className="pt-2 text-justify text-lg text-gray-600">
              <span className="font-semibold">
                Оптовым клиентам повышенный процент выплаты
              </span>
              , обсуждение условий индивидуально.
            </p>
          </div>
        </div>
      </section>
      <div className="border border-t-gray-300" />
      <ContactSection />
    </>
  )
}

export default PricePage
